import "./index.css"
import img from "../../asset/images/gallery.png"
import ext from "../../asset/images/11.png"
import line from "../../asset/images/line.png"
import int from "../../asset/images/12.png"
import doors from "../../asset/images/13.png"
import cabinets from "../../asset/images/14.png"
import ext_stain from "../../asset/images/15.png"
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation";

import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
SwiperCore.use([Autoplay, Pagination]);
function Services() {

    const data = [
        {
            heading: 'Exterior Paint',
            url: ext,
            content: "Boosting curb appeal can be simple with exterior paint that can make your house feel like a home. Whether it's architectural details like shutters or backyard staples like picnic tables, our smart painters can transform outdoor spaces in a few coats of paint."
        },
        {
            heading: 'Interior Paint',
            url: int,
            content: 'Were up for a challenge! Give us your toughest wall project. Dramatic ideas may take a few extra steps or multiple colors, but the finished results will speak for themselves. No matter the room, our smart painters will give your space a one-of-a- kind update.'
        },
        {
            heading: 'Doors',
            url: doors,
            content: "Our smart painters make it easier than ever to give front doors a welcoming update. It’s simple and quick, but they'll add a colorful boost to your curb appeal while leaving the perfect first impression on your guests."
        },
        {
            heading: 'Cabinets',
            url: cabinets,
            content: 'Looking to give your bathroom or kitchen a facelift? Painting cabinets is our speciality, it will make your space feel fresh without the price tag of a renovation. Allow our smart painters to make your cabinets look brand new.'
        },
        {
            heading: 'Exterior Stain',
            url: ext_stain,
            content: "Did someone call for warm weather and sunny skies this weekend? Let us take out our paintbrush and stain to get a head start on your exterior staining projects. Whether you're staining your deck or starting small with a picnic table refresh, our smart painters can help you."
        }
    ]

    return (
        <div id="service">
            <div>
                <div className="theme_heading">
                    <div>
                        <h1>SERVICES</h1>
                    </div>

                    <div className="img_div">
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className="image_wrapper">
                    <img src={img} alt="" />
                </div>
                <p className="services_p">We understand the importance of trust in the contractor you are inviting into your home or business, and our goal is to achieve this high level of trust before the project even begins. Our <span>smart</span> painters make sure the project is completed correctly, efficiently, and with minimal interruption to the home or business.</p>
            </div>
            <div className='carousal_contaier'>
                <Swiper
                    pagination={{ "clickable": true }}
                    loop={true}
                    navigation={true}
                    spaceBetween={0}

                    modules={[Pagination, Navigation]}
                    // loopFillGroupWithBlank={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        850: {
                            slidesPerView: 2,
                        },
                        1200: {
                            slidesPerView: 3,
                        },
                    }}
                    autoplay={{
                        "delay": 5000,
                        "disableOnInteraction": false
                    }}
                     className="mySwiper">
                        

                    {
                        data.map((v, i) => {
                            return (
                                <SwiperSlide key={i}>
                                    <div className="team_member_wrapper">
                                        <h3> {v["heading"]}</h3>
                                        <img src={v["url"]} alt="" />
                                        <p>{v["content"]}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </div>
    )
}
export default Services;