import './index.css'
import emailjs from '@emailjs/browser';
import { useState } from 'react';

export default function FormContainer() {
    const [toggle, setToggle] = useState(true)
    const [loading, setLoading] = useState(false)

    function sendEmail(e) {
        setLoading(true)
        e.preventDefault();
        emailjs.sendForm('service_if4gwfa', 'template_wlzpl4n', e.target, 'lkbwoMfk1t9PmJrQJ')
            .then(() => {
                setLoading(false)
                setToggle(true)
                setToggle(false)
            }, () => {
                setLoading(false)
            });
    }
    return (
        <div className='form_container'>
            <div className='partner_heading'>
                <h1>FILL OUT THE GIVEN FORM</h1>
            </div>
            <div className='form_wrapper'>
                {
                    toggle ?
                        <form onSubmit={sendEmail}>
                            <div className="row">
                                <div className="i_row">
                                    <div className="">
                                        <label className="form-label" htmlFor="name">
                                            Full Name<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="name"
                                            placeholder="Enter Full Name"
                                            type="text"
                                            id="name"
                                            className=""
                                            required
                                        />
                                    </div>
                                    <div className="">
                                        <label className="form-label" htmlFor="phone">
                                            Phone<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="phone"
                                            placeholder="Enter phone"
                                            type="phone"
                                            id="phone"
                                            className=""
                                            required

                                        />
                                    </div>
                                </div>
                                <div className='i_row'>
                                    <div className="">
                                        <label className="form-label" htmlFor="email">
                                            Email address<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="email"
                                            placeholder="Enter email"
                                            type="email"
                                            id="email"
                                            className=""
                                            required

                                        />
                                    </div>
                                    <div className="">
                                        <label className="form-label" htmlFor="address">
                                            Address<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="address"
                                            placeholder="Enter address"
                                            type="text"
                                            id="address"
                                            className=""
                                            required

                                        />
                                    </div>
                                </div>
                                <div className='i_row'>
                                    <div className="">
                                        <label className="form-label" htmlFor="city">
                                            City<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="city"
                                            placeholder="Enter city"
                                            type="text"
                                            id="city"
                                            className=""
                                            required

                                        />
                                    </div>
                                    <div className="">
                                        <label className="" htmlFor="state">
                                            State<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="state"
                                            placeholder="Enter state"
                                            type="text"
                                            id="state"
                                            className=""
                                            required

                                        />
                                    </div>
                                    <div className="">
                                        <label className="" htmlFor="zipcode">
                                            Zip Code<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="zipcode"
                                            placeholder="Enter Zip Code"
                                            type="text"
                                            id="zipcode"
                                            className=""
                                            required

                                        />
                                    </div>
                                </div>
                                <div className='m_d_row'>
                                    <div className="">
                                        <label className="" htmlFor="work">
                                            Type of Work (Residential or Commercial)<span className='red_star'>*</span>
                                        </label>
                                        <div className="radio_wrap">
                                            <div className="dd_conte">
                                                <input
                                                    className=""
                                                    type="radio"
                                                    name="work"
                                                    id="work"
                                                    value='Residential'
                                                    required

                                                />
                                                <label className="" htmlFor="inlineRadio1">
                                                    Residential
                                                </label>
                                            </div>
                                            <div className="dd_conte">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="work"
                                                    id="work"
                                                    value='Commercial'
                                                    required


                                                />
                                                <label className="" htmlFor="inlineRadio2">
                                                    Commercial
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="">
                                        <label className="" htmlFor="message">
                                            Message<span className='red_star'>*</span>
                                        </label>
                                        <input
                                            name="message"
                                            placeholder="Tell us about your work"
                                            type="text"
                                            id="message"
                                            className=""
                                            required
                                        />
                                    </div>
                                </div>
                                <div className='i_row'>
                                    <div className="">
                                        <label className="" htmlFor="hearabout">
                                            How Did You Hear About Us? (Referral, Facebook, Google Search,
                                            Advertisement, Other).
                                        </label>
                                        <input
                                            name="hearabout"
                                            type="text"
                                            id="hearabout"
                                            className=""

                                        />
                                    </div>
                                </div>
                                <div className="d_row">
                                    <label className="" htmlFor="formBasicHearAbout">
                                        How Soon Are You Looking To Complete The Work? (Planning, Ready-To-Go,
                                        A Few Months Out)<span className='red_star'>*</span>
                                    </label>
                                    <div className="radio_wrap">
                                        <div className="dd_conte">
                                            <input
                                                className=""
                                                type="radio"
                                                name="complete"
                                                id="inlineRadio3"
                                                value='In Planning'
                                                required

                                            />
                                            <label className="" htmlFor="inlineRadio3">
                                                In Planning
                                            </label>
                                        </div>
                                        <div className="dd_conte">
                                            <input
                                                className=""
                                                type="radio"
                                                name="complete"
                                                id="inlineRadio4"
                                                value='Ready-to-Go'
                                                required

                                            />
                                            <label className="" htmlFor="inlineRadio4">
                                                Ready-to-Go
                                            </label>
                                        </div>
                                        <div className="dd_conte">
                                            <input
                                                className=""
                                                type="radio"
                                                name="complete"
                                                id="inlineRadio5"
                                                value='Still a few months out'
                                                required

                                            />
                                            <label className="" htmlFor="inlineRadio5">
                                                Still a few months out
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className='i_row'>
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="form_button">

                                        Send Me My Free Quote!

                                    </button>
                                </div>

                            </div>
                        </form>
                        :
                        <div className='msg_div'>
                            Thank you. We've received your message and we will get back to you shortly.
                        </div>
                }
            </div >
        </div >
    )
}