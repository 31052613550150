import './style.css'
import img from "../../asset/images/contact.png"
import line from "../../asset/images/line.png"

function Contact() {
    return (
        <div className='contact_wrapper' id='contact'>
            <div>
                <div className="theme_heading">
                    <div>
                        <h1>CONTACT&nbsp;US</h1>
                    </div>

                    <div className="img_div">
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className='image_wrapper'>
                    <img src={img} alt="" />
                </div>
                <p className='services_p'>
                    We would love to help you find the perfect color, but finding the right contractor is just as important! Whether you're looking to make your bedroom cozier or turn your backyard into an oasis, let our <span>smart</span> painters make it easier than ever to live a colorful life at home. Call us at 443-326-4813 or fill out the form below and we will get back to you to you with an estimate.
                </p>
                <p className='services_p'>
                    Call us at <span>443-326-4813</span> or fill out the form below and we will get back to you to you with an estimate.
                </p>
            </div>
        </div>
    )
}

export default Contact;