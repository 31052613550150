import './index.css'
import img from '../../asset/images/cover.png'
import { HashLink } from 'react-router-hash-link';

export default function Cover() {
    return (
        <div className='cover_container' id='home'>
            <div className='cover_image'>
                <img src={img} alt="" />
            </div>
            <div className='cover_content'>
                <div className='cover_wrapper'>
                    <h1>Painting and Staining Services</h1>
                    <p>We offer interior and exterior, residential and commercial painting and staining services. Along with this we provide power washing, wallpaper stripping, wall preparation and repair.
                    </p>
                    <HashLink smooth to={'/#service'}><button>read more</button></HashLink>
                    {/* <button>read more</button> */}
                </div>

            </div>
        </div>
    )
}