import About from "../components/about";
import Career from "../components/career";
import Contact from "../components/contact";
import Cover from "../components/cover";
import Footer from "../components/footer";
import FormContainer from "../components/form";
import Navbar from "../components/navbar";
import Partner from "../components/partners";
import Services from "../components/services";
import React, { useRef, useEffect, useState } from "react";
import userEvent from "@testing-library/user-event";

const getDimensions = ele => {
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop - 400
    const offsetBottom = offsetTop + height;

    return {
        height,
        offsetTop,
        offsetBottom,
    };
};

function Home() {

    const [visibleSection, setVisibleSection] = useState();
    const coverref = useRef(null);
    const partnerref = useRef(null);
    const aboutref = useRef(null);
    const servicesref = useRef(null);
    const careerref = useRef(null);
    const contactref = useRef(null);
    const formref = useRef(null);
    const headerref = useRef(null)

    const sectionRefs = [
        { section: "header", ref: headerref },
        { section: "coverr", ref: coverref },
        { section: "partnerr", ref: partnerref },
        { section: "aboutt", ref: aboutref },
        { section: "servicess", ref: servicesref },
        { section: "careerr", ref: careerref },
        { section: "contact", ref: contactref },
        { section: "formm", ref: formref }
    ];

    useEffect(() => {
        const handleScroll = () => {
            // const { height: headerHeight } = getDimensions(headerref.current);
            const scrollPosition = window.scrollY + 100
            const selected = sectionRefs.find(({ section, ref }) => {
                const ele = ref.current;
                if (ele) {
                    const { offsetBottom, offsetTop } = getDimensions(ele);
                    return scrollPosition > offsetTop && scrollPosition < offsetBottom;
                }
            });
            if (selected && selected.section !== visibleSection) {
                setVisibleSection(selected.section);
            } else if (!selected && visibleSection) {
                setVisibleSection(undefined);
            }
        };
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [visibleSection]);

    return (
        <>
            <Navbar activeSection={visibleSection} />
            <div className="coverr" ref={coverref}>
                <Cover />
            </div>
            <div className="partnerr" ref={partnerref}>
                <Partner />
            </div>
            <div className="aboutt" ref={aboutref}>
                <About />
            </div>
            <div className="servicess" ref={servicesref}>
                <Services />
            </div>
            <div className="careerr" ref={careerref}>
                <Career />
            </div>
            <div className="contact" ref={contactref}>
                <Contact />
                <FormContainer />
                <Footer />
            </div>
        </>
    );
}

export default Home;