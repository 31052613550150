import './index.css'
import Modal from "react-modal";
import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { useEffect } from 'react';
Modal.setAppElement("#root");

const url = "https://fungi-fi.us14.list-manage.com/subscribe/post?u=1242764b6c0abcd5c58c0def5&amp;id=e638f6e138&amp;f_id=0016eee0f0";

const CustomForm = ({ status, message, onValidated }) => {
    let email;

    const submit = () =>
        email && email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    useEffect(() => {
        email.focus()
    }, [])

    return (
        <div>
            <input
                className='subscribe_input'
                ref={node => (email = node)}
                type="email"
                placeholder="Your email"
                required={true}
            />
            <br />
            <div className='btn_container'>
                <button onClick={submit}>Submit </button>
            </div>
            <span className='unsub'><a href="https://fungi-fi.us14.list-manage.com/unsubscribe?u=1242764b6c0abcd5c58c0def5&id=e638f6e138" target={"_blank"}>If you are already subscribed, click here to Unsubscribe.</a></span>


            {status === "error" && (
                <div
                    style={{ color: "red", marginTop: "10px" }}
                    dangerouslySetInnerHTML={{ __html: 'This email does not appear to be valid.' }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "green", marginTop: "10px" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )
            }

        </div>
    );
};


export default function SubscribeModal() {

    const [isOpen, setIsOpen] = useState(false);

    function toggleModal(state) {
        setIsOpen(state);
    }

    return (
        <div className="Moday_cont">
            <button onClick={() => toggleModal(true)}>subscribe</button>

            <Modal
                isOpen={isOpen}
                onRequestClose={() => toggleModal(false)}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={400}>
                <h3 className='modal_heading'> Subscribe to our Newsletter </h3>

                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => {
                        if (status === 'success') {
                            setTimeout(() => {
                                toggleModal(false);
                            }, 1800);
                        }
                        return (
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                            />
                        )
                    }}
                />
            </Modal>
        </div>
    );
}
