import "./index.css"
import img from "../../asset/images/logo.png"
import { FaFacebook, FaInstagram, FaTwitterSquare } from 'react-icons/fa';
import { HashLink } from 'react-router-hash-link';


function Footer() {
    return (
        <div className="footer_container_bg">
            <div className="footer_container">
                <div className="footer_logo">
                    <HashLink smooth to={'/#home'}><img src={img} alt="" /></HashLink>
                </div>
                <div className="footer_middle">
                    <span><HashLink smooth to={'/#home'}>Home</HashLink></span>
                    <span><HashLink smooth to={'/#about'}>About Us</HashLink></span>
                    <span><HashLink smooth to={'/#service'}>Services</HashLink></span>
                    <span><HashLink smooth to={'/#partner'}>Our Partners</HashLink></span>
                    <span><HashLink smooth to={'/#career'}>careers</HashLink></span>
                    <span><HashLink smooth to={'/#contact'}>Contact us</HashLink></span>
                </div>
                <div className="footer_right">
                    <span>Social Media</span>
                    <div className="icons_container">
                        <span onClick={() => window.open('https://www.facebook.com/smrtcont/', '_blank')}><FaFacebook /></span>
                        <span onClick={() => window.open('https://www.instagram.com/smrcont/', '_blank')}><FaInstagram /></span>
                        <span onClick={() => window.open('https://twitter.com/smrtcont', '_blank')}><FaTwitterSquare /></span>
                    </div>
                    <span>Call us <br />443-326-4813</span>
                </div>
            </div>
        </div>

    )
}

export default Footer;