import "./index.css"
import img from "../../asset/images/career.jpg"
import line from "../../asset/images/line.png"

function Career() {
    return (
        <div id="career">
            <div>
                <div className="theme_heading">
                    <div>
                        <h1>CAREERS</h1>
                    </div>

                    <div className="img_div">
                        <img src={line} alt="" />
                    </div>
                </div>
                <div className="image_wrapper">
                    <img src={img} alt="" />
                </div>
                <p className="career_p">Start Your Career With Us!</p>
                <p className="services_p">Smart Contracting is looking for painters to join our team. We’re a full-service painting company serving the  <span>Baltimore, Howard and Prince Georges County</span> areas. We value our smart employees—Well-trained, qualified, and licensed professionals offer the best service to our customers, and they make our team stronger. We take pride in our community— when you work with us, we help you work smarter, not harder. Call us for a job opportunity now @ <span>443-326-4813</span></p>
            </div>
        </div>
    )
}
export default Career;