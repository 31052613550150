import "./index.css"
import img from "../../asset/images/home_bg.jpg"
import line from "../../asset/images/line.png"
import img1 from "../../asset/images/10.png"


function About() {
    return (
        <div id="about">
            <div className="theme_heading">
                <div>
                    <h1>ABOUT&nbsp;US</h1>
                </div>

                <div className="img_div">
                    <img src={line} alt="" />
                </div>
            </div>


            <div className="image_wrapper">
                <img src={img} alt="" />
            </div>
            <div className="about_bottom">
                <div className="paint_image">
                    <div><img src={img1} alt="" /></div>
                </div>
                <div className="about_bottom_text">
                    <p>
                        We use the highest quality paint products– <span>Sherwin Williams</span> interior paint and take great care to protect all un-painted surfaces and thoroughly clean up our work areas at the end of each day.
                    </p>
                    <p>
                        It brings our staff great joy and a sense of pride for a job well done.
                    </p>
                </div>
            </div>
        </div>
    )
}
export default About;