import img1 from "../../asset/images/1.png"
import img2 from "../../asset/images/2.png"
import img3 from "../../asset/images/3.png"
import img4 from "../../asset/images/4.png"
import img5 from "../../asset/images/5.png"
import img6 from "../../asset/images/gallery.png"
import img7 from "../../asset/images/9.png"
import img8 from "../../asset/images/7.png"
import img9 from "../../asset/images/6.png"


import "./style.css"

function Partner() {
    return (
        <div className="partner_container" id="partner">
            <div className="partner_heading">
                <h1>OUR PARTNERS</h1>
            </div>
            <div className="partner_images">
                <div>
                    <img className="fi_images" onClick={() => window.open('https://www.porch.com', '_blank')} src={img5} alt="" />
                </div>
                <div>
                    <img className="sq_images" onClick={() => window.open('https://www.sherwin-williams.com ', '_blank')} src={img4} alt="" />
                </div>
                <div>
                    <img className="m_image" onClick={() => window.open('https://www.painterbids.com ', '_blank')} src={img3} alt="" />
                </div>
                <div>
                    <img className="sq_images" onClick={() => window.open('https://www.angi.com ', '_blank')} src={img2} alt="" />
                </div>
                <div>
                    <img className="ls_images" onClick={() => window.open('https://www.homeadvisor.com', '_blank')} src={img1} alt="" />
                </div>
            </div>

            <div className="red_line"></div>

            <div className="image_wrapper">
                <img src={img6} alt="" />
            </div>


            <div className="partner_content">
                <p>We offer Interior and Exterior, Residential and Commercial Painting and Staining Services. Along with Power Washing, Wallpaper Stripping, Wall Preparation and Repair. We offer the highest level of quality work at competitive prices for residential and commercial applications.</p>
                <br />
                <p>Our painters are smart and provide clean and efficient service. All of our work is guaranteed. We service the Baltimore, Howard and Prince Georges County areas and their surrounding communities.</p>

            </div>
            <div>
                <div className="partner_heading">
                    <h1>OVER THE PAST 14 YEARS</h1>
                </div>
                <div className="past_wrapper">
                    <div className="sub_past_wrapper">
                        <img src={img7} alt="" />
                        <div>
                            <p>425</p>
                            <p>Customers Served</p>
                        </div>

                    </div>
                    <div className="sub_past_wrapper">
                        <img src={img8} alt="" />
                        <div>
                            <p>34,310</p>
                            <p>Gallons of Paint Used</p>
                        </div>

                    </div>
                    <div className="sub_past_wrapper">
                        <img src={img9} alt="" />
                        <div>
                            <p>1530</p>
                            <p>Projects Completed</p>
                        </div>
                    </div>
                </div>
                <div className="red_line">

                </div>
            </div>
        </div>
    )
}

export default Partner;