import './index.css'
import img from '../../asset/images/logo.png'
import { HashLink } from 'react-router-hash-link';
import { AiOutlineMenu } from 'react-icons/ai'
import { VscChromeClose } from 'react-icons/vsc'
import { useState } from 'react';
import { useEffect } from 'react';

import SubscribeModal from './modal';


export default function Navbar({ activeSection }) {
    const [toggle, setToggle] = useState(false)
    const [prevScrollPos, setPrevScrollPos] = useState(0);

    const handleClick = (value) => {
        setToggle(!value)
    }

    const handleScroll = () => {
        const currentScrollPos = window.scrollY
        if (currentScrollPos > prevScrollPos) {
            const header = document.querySelector('.navbar_container_wrapper');
            header.classList.add('hide_navbar')
        }
        else {

            const header = document.querySelector('.navbar_container_wrapper');
            header.classList.remove('hide_navbar');
        }

        setPrevScrollPos(currentScrollPos)
    }



    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll)
    })


    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const scrollTop = window.scrollY;
        console.log('')
        const header = document.querySelector('.navbar_container_wrapper');
        scrollTop > 0 ? header.classList.add('navbar_container_up') : header.classList.remove('navbar_container_up');

    };

    return (
        <>
            <div className='navbar_container_wrapper'>
                <div className='navbar_container'>
                    <div className='logo_container'><HashLink smooth to={'/#home'}><img src={img} alt="" /></HashLink></div>
                    <div className='menu_container'>
                        <span className={activeSection === 'coverr' ? 'active_menu' : ''}><HashLink smooth to={'/#home'}>Home</HashLink></span>
                        <span className={activeSection === 'aboutt' ? 'active_menu' : ''}><HashLink smooth to={'/#about'}>About Us</HashLink></span>
                        <span className={activeSection === 'servicess' ? 'active_menu' : ''}><HashLink smooth to={'/#service'}>Services</HashLink></span>
                        <span className={activeSection === 'partnerr' ? 'active_menu' : ''}><HashLink smooth to={'/#partner'}>Our Partners</HashLink></span>
                        <span className={activeSection === 'careerr' ? 'active_menu' : ''}><HashLink smooth to={'/#career'}>careers</HashLink></span>
                        <span className={activeSection === 'contact' ? 'active_menu' : ''}><HashLink smooth to={'/#contact'}>Contact us</HashLink></span>
                    </div>
                    <div className='btn_container'>
                        <SubscribeModal />
                    </div>
                </div>
            </div>
            <div className="mobile_navbar_container">
                <div className='m_logo'>
                    <HashLink smooth to={'/#home'}><img src={img} alt="" /></HashLink>
                </div>

                <div className="m_icons_container">
                    <div className='btn_container'>
                        <SubscribeModal />
                    </div>
                    <span onClick={() => handleClick(toggle)} className="toggle_btn">
                        {toggle ? <VscChromeClose /> : <AiOutlineMenu />}
                    </span>
                </div>
                {toggle ?
                    <div className="menu_section_mobile">
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'/#home'}>Home</HashLink>
                        </span>
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'/#about'}>About Us</HashLink>
                        </span>
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'/#service'}>Services</HashLink>
                        </span>
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'/#partner'}>Our Partners</HashLink>
                        </span>
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'/#career'}>careers</HashLink>
                        </span>
                        <span onClick={() => handleClick(toggle)} >
                            <HashLink smooth to={'/#contact'}>Contact us</HashLink>
                        </span>
                    </div>
                    : null
                }
            </div>
        </>
    )
}